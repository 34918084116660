import { Dayjs } from 'dayjs';
import dayjs from './dayjs';

const localeTimeUnits = {
  second: ['секунду', 'секунд'],
  minute: ['хвилину', 'хвилин'],
  hour: ['годину', 'годин'],
  day: ['день', 'днів'],
  week: ['тиждень', 'тижнів'],
  month: ['місяць', 'місяців'],
  year: ['рік', 'років'],
};

const dateDiffMeasures = ['year', 'month', 'week', 'day', 'hour', 'minute', 'second'] as const;

function getLocaleDateDiff(diff: number, measure: (typeof dateDiffMeasures)[number]) {
  diff = Math.trunc(diff);

  if (diff === 1) {
    return `${localeTimeUnits[measure][0]} тому`;
  }

  return `${diff} ${localeTimeUnits[measure][1]} тому`;
}

function localeDateDiff(date1: Date | string | Dayjs, date2: Date | string | Dayjs) {
  date1 = dayjs(date1).utc();
  date2 = dayjs(date2).utc();
  // console.log('Date1:', date1);
  // console.log('Date2:', date2);
  for (const dateDiffMeasure of dateDiffMeasures) {
    const diff = date1.diff(date2, dateDiffMeasure);

    if (diff < 1) {
      continue;
    }

    return getLocaleDateDiff(diff, dateDiffMeasure);
  }

  return 'менше секунди тому';
}

export default localeDateDiff;
